import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import v from 'vant';
import 'vant/lib/index.css';
import {Toast, Dialog} from "vant";
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(v);

// // 全局方法 this. 的调用方式
// declare module 'vue/types/vue' {
//   // eslint-disable-next-line prettier/prettier
//   interface Vue {
//     $toast: Toast
//     $dialog: Dialog
//   }
// }

Toast.setDefaultOptions({
  overlay: true, // 遮罩
  forbidClick: true, // 禁止背景点击
  duration: 2500, // 2秒
})

Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
